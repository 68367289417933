// footer
.footer {
	background: #f5f5f5;
	z-index: -1;
	p {
		font-size: $h5-font-size;
	}
	.footer-contact {
		background: $white;
		.container-holder {
			max-width: 750px;
			margin: auto;
			.column {
				text-align: center;
			}
		}
		ul.footer-contact-buttons {
			display: flex;
			gap: 1rem;
			flex-flow: row wrap;
			justify-content: center;
			align-items: center;
			margin: 3vh 0;
			li {
				a {
					display: block;
					background: $red;
					width: 240px;
					height: 65px;
					color: $white;
					display: flex;
					justify-content: space-between;
					align-items: center;
					text-decoration: none;
					flex-flow: row-reverse wrap-reverse;
					padding: 0.5rem 0.85rem 0.5rem 1rem;
					font-size: 20px;
					font-weight: 700;
					border-radius: 3px;
					i {
						font-size: 30px;
						opacity: 0.75;
						transition: 0.35s;
						margin-left: auto;
						margin-right: 0;
						transform: translateY(12px);
					}
					&:hover {
						background: rgba($red, 0.8);
						i {
							transform: translateY(12px) rotate(360deg);
							opacity: 1;
							transition: 0.35s;
						}
					}
				}
				.list-item-suffix {
					order: -1;
					width: 100%;
					font-size: 14px;
					text-align: left;
					color: $white;
					opacity: 0.75;
				}
			}
		}
	}
	section.footer-partners {
		padding: 6vh 0;
		text-align: center;
		bottom: 0;
		background: #f5f5f5;
		ul.footer-partners {
			display: flex;
			flex-flow: row;
			gap: 1.5rem;
			align-items: center;
			justify-content: center;
			li {
				max-width: 180px;
				opacity: 0.85;
				transition: 0.35s ease-in;
				&:hover {
					opacity: 1;
					transition: 0.35s ease;
				}
				.list-item-title {
					display: none;
				}
			}
			@include media-breakpoint-down(md) {
				flex-flow: column;
				gap: 0.85rem;
			}
		}
	}
	.footer-socket {
		background: $white;
		padding: 6vh 0;
		box-shadow: $shadow;
		.list {
			width: 100%;
			display: flex;
			gap: 0.75rem;
			justify-content: center;
			a {
				text-decoration: none;
				color: $blue;
				&:hover {
					font-weight: 700;
				}
			}
		}
	}
}
