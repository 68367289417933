// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}
// intro-section
.intro-section {
	margin: 6vh 0;
	.container {
		margin: 4vh auto;
	}
	h1.title {
		font-size: 48px !important;
	}
	.subtitle {
		color: $green;
		font-weight: 400;
	}
	.usps .text a {
		color: $blue;
		@extend .arrow-link;
	}
	@include media-breakpoint-down(md) {
		.container .container-holder {
			gap: 1rem;
		}
	}
}

// plattegronden
.content-section.plattegronden {
	margin: 0 0 6vh 0;
	padding: 6vh 0;
	background-color: $blue;
	position: relative;
	background-image: url("/images/bg_pgz.jpg") !important;

	&::before {
		content: "";
		background: linear-gradient(rgba($blue, 0.85), rgba($blue, 1));
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-size: contain;
		background-repeat: no-repeat;
		position: absolute;
	}

	.title,
	.subtitle {
		color: $white;
	}

	.subtitle {
		font-weight: 400;
	}
	.item {
		max-width: 100% !important;
		flex-basis: 100% !important;
		.card {
			display: flex;
			flex-flow: row;
			background: none;
			border: none;
			div.card-image {
				max-width: 50%;
				&::after {
					content: "\f00e";
					font-family: $font-awesome;
					font-size: 76px;
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: rgba(black, 0.05);
					color: $white;
					display: flex;
					align-items: center;
					justify-content: center;
					transition: 0.5s;
					pointer-events: none;
				}

				&:hover {
					background: rgba(black, 0);

					&::after {
						transform: scale(2);
						opacity: 0;
						transition: 0.5s;
					}
				}
			}
			.card-body {
				padding: 3.5rem;
				*,
				li::before {
					color: $white !important;
				}
				.card-buttons {
					display: none;
				}
				.card-title {
					font-size: 30px !important;
					font-weight: 900;
				}
				.card-subtitle {
					font-weight: 600;
					font-size: 18px;
				}
				b {
					font-weight: 900;
				}
			}
		}
		@include media-breakpoint-down(md) {
			.card {
				flex-flow: column;
				div.card-image {
					max-width: 100%;
				}
				.card-body {
					padding: 1rem 0;
				}
			}
		}
	}
}
// pricing page
&.pricing {
	.card {
		@extend .pricing-card;
	}
	.grid-items {
		> :nth-child(even) {
			.card {
				background: #eeee;
			}
		}
	}
}
// pricing section
.pricing-section {
	background: $gray-dark !important;
	margin: 6vh auto;
	padding: 12vh 0;
	.title,
	.subtitle {
		color: $white;
	}
	.title {
		font-size: 48px !important;
	}
	.description {
		font-size: 20px;
	}
	.intro {
		color: $white;
		margin-bottom: 3vh;
		.container-holder {
			max-width: 750px;
			margin: auto;
			.column {
				text-align: center;
			}
		}
	}

	.pricing-cards {
		.card {
			@extend .pricing-card;
		}
		.grid-items {
			> :nth-child(2) {
				.card {
					background: $white;
				}
			}
		}
	}
	@include media-breakpoint-down(md) {
		.card-title {
			font-size: 36px !important;
		}
		.card-subtitle {
			font-size: 24px !important;
		}
		.card-description {
			font-size: 18px !important;
		}
	}
}

// services-section
.services-section {
	margin: 6vh 0;
	.intro {
		margin: 6vh auto;
		.text {
			flex-basis: 70%;
			max-width: 70%;
			.subtitle {
				color: $green;
				font-weight: bold;
			}
		}
		.column.image {
			flex-basis: 30%;
			max-width: 30%;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: 100%;
			}
		}
		@include media-breakpoint-down(md) {
			.text,
			.column.image {
				flex-basis: 90%;
				max-width: 90%;
			}
		}
	}
	.services-grid {
		$items-to-display: 3;
		@for $i from 1 through $items-to-display {
			.item:nth-child(#{$i}) {
				display: block;
			}
		}

		.item {
			display: none;
			.card {
				border: 0;
				background: none;
				.card-image {
					border-radius: 0;
				}
				.card-body {
					padding: 1rem 0;
					.card-subtitle {
						display: none;
					}
					.card-btn {
						all: unset;
						@extend .arrow-link;
						color: $blue;
					}
				}
			}
		}
	}
}

// portfolio-section
.portfolio-section {
	margin: 6vh 0;
	padding: 6vh 0;
	background-color: $blue;
	position: relative;
	background-image: url("/images/bg_pgz.jpg");
	&::before {
		content: "";
		background: linear-gradient(rgba($blue, 0.85), rgba($blue, 1));
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-size: contain;
		background-repeat: no-repeat;
		position: absolute;
	}

	.title,
	.subtitle {
		color: $white;
	}
	.subtitle {
		font-weight: 400;
	}
	.portfolio-items {
		margin: 6vh 0;

		.grid-items {
			.item {
				flex-basis: 50% !important;
				max-width: 50% !important;
				.card {
					border: 0;
					.card-body {
						display: none !important;
					}

					&::after {
						content: "\f00e";
						font-family: $font-awesome;
						font-size: 76px;
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background: rgba(black, 0.05);
						color: $white;
						display: flex;
						align-items: center;
						justify-content: center;
						transition: 0.5s;
					}
					&:hover {
						background: rgba(black, 0);
						&::after {
							transform: scale(2);
							opacity: 0;
							transition: 0.5s;
						}
					}
				}
			}
			@include media-breakpoint-down(md) {
				.item {
					flex-basis: 100% !important;
					max-width: 100% !important;
				}
			}
		}
	}
	a {
		@extend .arrow-link;
		color: $white;
	}
}

// client section
.client-section {
	overflow: hidden;
	.container {
		margin: 6vh 0;
		width: 100%;
		max-width: 100%;
	}
	.owl-caption,
	.owl-nav,
	.owl-dots {
		display: none;
	}
	.owl-caption-holder {
		min-width: 100% !important;
		width: 100% !important;
	}
	.owl-item {
		display: flex;
		justify-content: center;
		overflow: visible;
		.item {
			width: 100%;
			min-height: 9vh;
			background-size: contain;

			display: flex;
			justify-content: center;
			.card {
				opacity: 0.75;
				transition: 0.35s ease;
				border: none;
				.card-img-overlay {
					background: none;
				}

				.card-image {
					display: flex;
					justify-content: center;

					width: 100% !important;
					img {
						max-height: 150px;
					}
				}
				.card-body {
					display: none !important;
				}
				&:hover {
					opacity: 1;
					transition: 0.35s ease;
				}
			}
		}
	}
}
// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	.container-one-column {
		.container-holder {
			max-width: 750px;
			margin: 0 auto;
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
