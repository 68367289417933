// eyecatcher

.eyecatcher {
	position: relative;
	background-color: $green-light;
	padding: 6vh 0;
	overflow: hidden;
	&::after {
		content: "";
		position: absolute;
		right: 0;
		top: -10vh;
		width: 50%;
		height: calc(100% + 10vh);
		background: url("/images/eyecatcher-background.png");
		background-position: top 110% right;
		background-repeat: no-repeat;
		background-size: cover;
		animation: myAnim 15s linear 0s infinite alternate both;
	}
	&.large {
		&::after {
			content: "";
			position: absolute;
			right: -10vw;
			top: -10vh;
			width: 50%;
			height: calc(100% + 10vh);
			background: url("/images/eyecatcher-background.png");
			background-position: top right;
			background-repeat: no-repeat;
			background-size: cover;
			animation: myAnim 15s linear 0s infinite alternate both;
		}
	}
	.one {
		flex-basis: 60% !important;
		max-width: 60% !important;
	}
	.two {
		flex-basis: 40% !important;
		max-width: 40% !important;
	}
	.subtitle {
		color: $green;
		font-weight: 400;
		font-size: 30px;
	}
	.description {
		font-weight: 900;
		font-size: 20px;
		ul {
			margin: 1rem 0 2rem 1rem;
		}
	}
	.btn {
		display: inline-flex;
		max-width: fit-content;
		z-index: 2;
	}
	@include media-breakpoint-down(md) {
		position: relative;
		.title {
			font-size: 36px !important;
		}
		.subtitle {
			font-size: 24px !important;
		}
		.description {
			font-size: 16px !important;
		}
		.one {
			flex-basis: 100% !important;
			max-width: 100% !important;
		}

		.two {
			min-height: 25vh;
			flex-basis: 100% !important;
			max-width: 100% !important;
		}
		&::after {
			height: 30vh;
				bottom: 0% !important;
				left: 0 !important;
				top: auto !important;
				right: auto !important;
				width: 100% !important;
				z-index: 1;
				background-position: top center;
		}
		&.large {
			&::after {
				height: 25vh;
				bottom: 0% !important;
				left: 0 !important;
				top: auto !important;
				right: auto !important;
				width: 100% !important;
				z-index: 1;
				background-position: top center;
			}
		}
	}
}

@keyframes myAnim {
	0% {
		transform: translate(0, 0) scale(1);
	}

	100% {
		transform: translate(100px, 30px) scale(1.1);
	}
}
