// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	transition: 0.5s;

	&.sticky {
		box-shadow: $shadow;
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: center;

				@include media-breakpoint-down(md) {
					padding-bottom: 3vh;
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
	}

	// logo
	.logo {
		width: 100%;
		flex-basis: 25%;
		max-width: 25%;
		margin: 10px 10px 10px 0;

		a {
			@extend .d-block;

			img {
				max-width: 350px;
			}
		}
		@include media-breakpoint-down(md) {
			flex-basis: auto;
			max-width: 85%;
			overflow: hidden;
			img {
				max-width: 100% !important;
				object-fit: contain !important;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
		}

		@include media-breakpoint-up(xl) {
			margin: auto;
			flex-basis: 50%;
			max-width: 50%;
			.navbar-nav {
				margin: auto;
				width: 100%;
				display: flex;
				justify-content: center;
				.nav-item {
					.nav-link {
						font-weight: 700;
						color: $gray-dark;
						font-size: 16px;
					}
					&:hover,
					&.active {
						.nav-link {
							color: $red;
						}
					}
				}
			}
		}
	}
	div.header-btn {
		flex-basis: 25%;
		max-width: 25%;
		display: flex;
		justify-content: flex-end;

		a {
			@extend .btn;
			background: $gray-darker;
			color: $white;
			padding: 0.5rem 1.5rem;
			&:hover {
				background: rgba($gray-darker, 0.8);
				color: $white;
			}
		}
		@include media-breakpoint-down(md) {
			flex-basis: 100%;
			max-width: 100%;
			position: absolute;
			margin-left: auto;
			margin-right: auto;
			left: 0;
			right: 0;
			bottom: 0;
			a {
				transform: translate(-31%, 50%);
			}
		}
	}
	// site-switcher
	.site-switcher {
	}
}
