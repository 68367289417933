	.pricing-card {
		margin: 0 1.5rem;
		background: $green-light;
		padding: 2.5rem 1.5rem 0.5rem 1.5rem;
		border-radius: 20px;
		transition: 0.35s ease;
		width: 100%;
border: none;

		.card-subtitle {
			font-weight: 400 !important;
		}

		.title {
			color: $blue;
			font-weight: 900;
		}

		.card-btn {
			background: none;
			color: $blue;
			border: none;
			padding: 0;
			font-weight: 900;
			font-size: 24px;
		}

		&:hover {
			transform: scale(1.05);
			transition: 0.35s ease;
		}

		.card-description-content ul {
			@extend .custom-list-extra;
		}
	}
